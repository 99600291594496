import React, { useEffect, useRef, useState } from "react";
import { Toast } from "react-bootstrap";
import { PAGE_STATE_EVENT } from "../../libs/constants";
import { PageStateInterface } from "../../libs/helpers/pageState";
import BSIcon from "../BSIcon/BSIcon";
import { PageStates } from "../../libs/helpers/pageState";
import { reachGoal } from "../../libs/helpers/metrics";
import { delay } from "../../libs/helpers/devHelpers";

const toastHeaderByType = {
  [PageStates.error]: "bg-danger",
  [PageStates.info]: "bg-info",
  [PageStates.success]: "bg-success",
  [PageStates.warning]: "bg-warning"
};

const toastIconByType = {
  [PageStates.error]: "x-circle",
  [PageStates.info]: "question-circle",
  [PageStates.success]: "check2",
  [PageStates.warning]: "exclamation-triangle"
};

let _pageStateList: PageStateListType = []; // cache for page state toasts
let counter = 0; // throught counter for page state toasts

type PageStateListType = ({ id: number; needShow: boolean } & PageStateInterface)[];

export default function PageStatesManager() {
  // page state toasts helpers
  const [pageStateList, pageStateListSet] = useState<PageStateListType>([]);
  const removePageState = (id: number) => {
    _pageStateList = _pageStateList.map((item) => {
      if (item.id === id) {
        item.needShow = false;
        if (!item.delay) {
          reachGoal("Close_Global_State", { type: item.type, title: item.title });
        }
      }
      return item;
    });
    pageStateListSet([..._pageStateList]);
  };
  const PageStateHandler = (e: CustomEvent<PageStateInterface>) => {
    counter++;
    _pageStateList.push({ id: counter, needShow: true, ...e.detail });
    pageStateListSet([..._pageStateList]);
  };

  useEffect(() => {
    document.removeEventListener(PAGE_STATE_EVENT, PageStateHandler);
    document.addEventListener(PAGE_STATE_EVENT, PageStateHandler);
    return () => document.removeEventListener(PAGE_STATE_EVENT, PageStateHandler);
  }, []);

  useEffect(() => {
    const toastDiv = toastContainerRef.current;
    if (!toastDiv) return;
    delay(300).then(() => {
      // wait until DOM draws
      toastDiv.scrollTop = toastDiv.scrollHeight;
    });
  }, [pageStateList]);

  const toastContainerRef = useRef<HTMLDivElement>();
  return (
    <div ref={toastContainerRef} className="toast-container">
      <div className="toast-container__inner">
        {pageStateList.map((pageState) => {
          return (
            <Toast
              key={pageState.id}
              show={pageState.needShow}
              onClose={() => removePageState(pageState.id)}
              autohide={pageState.delay > 0}
              delay={pageState.delay}>
              <Toast.Header className={toastHeaderByType[pageState?.type]}>
                <BSIcon size={22} name={toastIconByType[pageState?.type]} />
                <strong className="ml-2 mr-auto">{pageState?.title}</strong>
                {pageState?.code && <small>code: {pageState?.code}</small>}
              </Toast.Header>
              <Toast.Body>{pageState?.message}</Toast.Body>
            </Toast>
          );
        })}
      </div>
    </div>
  );
}
