import { Router } from "next/router";
import React, { ReactElement, useEffect } from "react";
import { YMInitializer } from "react-yandex-metrika";
import { isDevEnvironment } from "../../libs/helpers/devHelpers";
import { hitPageView, YM_METRIKA_ID } from "../../libs/helpers/metrics";

const routeChangeCallback = (url: string) => {
  hitPageView(url);
};
// need to attach to event outside component
Router.events.off("routeChangeComplete", routeChangeCallback);
Router.events.on("routeChangeComplete", routeChangeCallback);

/**
 * Metrika init component using react-yandex-metrika
 * In prod mode it creates metrika object on a page and pass all data
 * In dev mode it only trigger debug info
 * @doc https://github.com/narkq/react-yandex-metrika/issues/47
 */
export default function YAMetrika(): ReactElement {
  useEffect(() => {
    hitPageView(window.location.pathname + window.location.search);
  }, []);

  return isDevEnvironment ? (
    <></>
  ) : (
    <YMInitializer accounts={[parseInt(YM_METRIKA_ID)]} options={{ defer: true }} version="2" />
  );
}
