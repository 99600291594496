/** include global styles here and any blocking things
 * to setup flux architecure
 *  @see https://nextjs.org/docs/basic-features/typescript#custom-app
 */

import React, { useState, useEffect } from "react";
import { AppProps } from "next/dist/next-server/lib/router/router";
import { useRouter } from "next/router";
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import "../styles/globals.scss";
import "../styles/landing.css"; // TODO: move to css modules
import "swiper/swiper.min.css";
import { Routes, storageTokenKey } from "../libs/constants";
import axios from "axios";
import { attachUserSessionListener } from "../libs/helpers/userSession";
import { UserContext, UserStateInterface } from "../libs/UserContext";
import { destroyFirebaseApp, initFirebaseApp } from "../libs/helpers/firebase";
import YAMetrika from "../components/Metrika/YAMetrika";
import { getFromStorage } from "../libs/helpers/storage";
import PageStatesManager from "../components/PageStates/PageStatesManager";
import { SWRConfig } from "swr";

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

    enabled: process.env.NODE_ENV === "production",

    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });
}

// setting BASE_URL for axios
axios.defaults.baseURL = process.browser ? process.env.NEXT_PUBLIC_API_BASE : process.env.API_BASE;
axios.defaults.transformResponse = (res) => {
  try {
    res = JSON.parse(res);
  } catch (err) {
    res = "<raw html response>"; // handle overloaded server response leads to js infinite loop
  }
  return res;
};

function MyApp({ Component, pageProps }: AppProps) {
  const [userContext, userContextSet] = useState<UserStateInterface>({
    userId: null,
    userRole: null,
    email: null,
    isEmailVerified: null,
    firebaseToken: null
  });

  useEffect(() => {
    if (pathname === Routes[500]) {
      return;
    }
    // attach auth sessions listeners
    const refresh = getFromStorage(storageTokenKey) as string;
    attachUserSessionListener(userContextSet, refresh);
  }, []);

  // init redirects for (un)authorized users
  const unauthorizedRoutesBlacklist = [
    Routes.Claims,
    Routes.ClaimBuddies,
    Routes.ClaimChat,
    Routes.Profile,
    Routes.ProfileBuddy,
    Routes.ProfileClaims,
    Routes.ProfileEdit,
    Routes.ProfilePhoto
  ];
  // need to exclude /registration until user profile will be creating on server
  const authorizedRoutesBlacklist = [Routes.Login, /* Routes.Registration, */ Routes.Reset, Routes.ResetConfirm];
  const { pathname, replace } = useRouter();
  useEffect(() => {
    if (
      userContext.userRole !== null &&
      (userContext.userId !== null ? authorizedRoutesBlacklist : unauthorizedRoutesBlacklist).includes(pathname)
    ) {
      replace(Routes.Home);
    }
  }, [userContext, pathname]);
  useEffect(() => {
    if (userContext.userId !== null) {
      initFirebaseApp(userContext.firebaseToken);
    } else {
      destroyFirebaseApp();
    }
  }, [userContext]);

  return (
    <div>
      <YAMetrika />
      <UserContext.Provider value={userContext}>
        <SWRConfig
          value={{
            // insert global config for swr here
            errorRetryInterval: 0,
            shouldRetryOnError: false
          }}>
          <Component {...pageProps} />
        </SWRConfig>
        <PageStatesManager />
      </UserContext.Provider>
    </div>
  );
}

export default MyApp;
