import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import { reachGoal } from "./metrics";

const config = {
  apiKey: "AIzaSyCxcNFGRcl3p1OoDvU4ZgaBPuW_TGIiOJg",
  authDomain: "tbuddy-chat.firebaseapp.com",
  databaseURL: "https://tbuddy-chat-default-rtdb.firebaseio.com/",

  projectId: "tbuddy-chat",
  appId: "1:107197754785:web:2e0637c89f0d9e40956fe0"
};

let firebaseApp: firebase.app.App | null = null;

export function initFirebaseApp(authToken: string) {
  if (process.browser && !firebase.apps.length) {
    console.log("init new firebase instance");
    firebaseApp = firebase.initializeApp(config);

    firebase.auth().signInWithCustomToken(authToken);
  }
}

export function destroyFirebaseApp() {
  if (process.browser && firebaseApp) {
    console.log("destroying existing firebase instance");
    firebaseApp.delete().then(() => {
      firebaseApp = null;
    });
  }
}
